import '../../css/components/ProjectImage.css';
import upendoImage1 from '../../assets/images/ChancebulonRender0000.png'

const ProjectImage = () => {
    return(
        <div id='projectImage'>
            <img id='upendoImage1' src = {upendoImage1} alt='Upendo' />
        </div>
    )
}
export default ProjectImage;